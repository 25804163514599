import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export interface IPublicationFormat {
    id: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetPublicationFormatsRequest extends IPaginateRequest {
    search?: string | null;
    order?: string | null;
    deleted?: boolean;
}

export interface IGetPublicationFormatsResponse extends IPaginateResponse<IPublicationFormat> {}

export interface IDeletePublicationFormatResponse {
    message: string;
}

export interface IReactivatePublicationFormatResponse {
    message: string;
}

export interface IReprocessBalanceResponse {
    message: string;
}

export interface ICreatePublicationFormatRequest {
    name: string;
}

export interface ICreatePublicationFormatResponse extends IPublicationFormat {}

export interface IUpdatePublicationFormatRequest {
    name: string;
}

export interface IUpdatePublicationFormatResponse extends IPublicationFormat {}

async function getPublicationFormats(param: IGetPublicationFormatsRequest): Promise<IGetPublicationFormatsResponse> {
    const response = await Api.get('/v1/publicationFormats', param);
    return response.data;
}

async function getPublicationFormat(id: string): Promise<IPublicationFormat> {
    const response = await Api.get(`/v1/publicationFormats/${id}`);
    return response.data;
}

async function createPublicationFormat(
    param: ICreatePublicationFormatRequest
): Promise<ICreatePublicationFormatResponse> {
    const response = await Api.post('/v1/publicationFormats', param);
    return response.data;
}

async function updatePublicationFormat(
    id: string,
    param: IUpdatePublicationFormatRequest
): Promise<IUpdatePublicationFormatResponse> {
    const response = await Api.put(`/v1/publicationFormats/${id}`, param);
    return response.data;
}

async function deletePublicationFormat(id: string): Promise<IDeletePublicationFormatResponse> {
    const response = await Api.delete(`/v1/publicationFormats/${id}`);
    return response.data;
}

async function reactivatePublicationFormat(id: string): Promise<IReactivatePublicationFormatResponse> {
    const response = await Api.post(`/v1/publicationFormats/reactivate/${id}`);
    return response.data;
}

export default {
    getPublicationFormats,
    getPublicationFormat,
    createPublicationFormat,
    updatePublicationFormat,
    deletePublicationFormat,
    reactivatePublicationFormat,
};
