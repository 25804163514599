import { FC } from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Link as RouteLink } from 'react-router-dom';

export interface IBreadcrumbCustomProps {
    options: Array<{
        name: string;
        route: string;
        tooltip?: string;
    }>;
}

const BreadcrumbCustom: FC<IBreadcrumbCustomProps> = ({ options }) => {
    return (
        <Breadcrumb paddingTop={5} paddingLeft={5} paddingBottom={5}>
            {options.map((it) => (
                <BreadcrumbItem key={it.name} as="h2">
                    <BreadcrumbLink as="div">
                        <RouteLink to={it.route}>
                            <Heading as="h2" size="md">
                                {it.name}
                                {!!it.tooltip && (
                                    <>
                                        {' '}
                                        <Tooltip label={it.tooltip}>
                                            <InfoOutlineIcon boxSize={4} />
                                        </Tooltip>
                                    </>
                                )}
                            </Heading>
                        </RouteLink>
                    </BreadcrumbLink>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};

export default BreadcrumbCustom;
