import { FC, useRef, useState } from 'react';
import {
    Box,
    Flex,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useDisclosure,
    Menu,
    MenuButton,
    MenuList,
    Avatar,
    Center,
    MenuDivider,
    MenuItem,
    useColorMode,
    Image,
    Text,
    Heading,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Link as RouteLink } from 'react-router-dom';

import Dialog, { IDialogProps } from '../../components/Dialog';
import Session, { ISession } from '../../utils/session/Session';

export default function HeaderMenu() {
    const navBarItensRef = useRef(navBarItens(Session.isAdmin()));
    const user = useRef<ISession | null>(Session.get());
    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen, onToggle } = useDisclosure();
    const [propDialog, setPropDialog] = useState<IDialogProps | null>(null);

    function logout() {
        Session.logout(false);
    }

    function openModalLogout() {
        setPropDialog({
            title: 'Finalizar sessão',
            message: 'Realmente deseja finalizar sua sessão?',
            onOk: logout,
            onClose: () => setPropDialog(null),
        });
    }

    return (
        <Box width="100%">
            <Flex
                bg={useColorModeValue('gray.100', 'gray.900')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
            >
                <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                    <RouteLink to="/media">
                        <Image boxSize="2rem" objectFit="cover" src="/images/logo_144X144.png" alt="logo" />
                    </RouteLink>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav navItens={navBarItensRef.current} />
                    </Flex>
                </Flex>

                <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
                    <Button onClick={toggleColorMode}>{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}</Button>

                    <Menu>
                        <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
                            <Avatar size={'sm'} referrerPolicy="no-referrer" name={user.current?.user?.name} />
                        </MenuButton>
                        <MenuList alignItems={'center'} maxW={250}>
                            <br />
                            <Center>
                                <Avatar size={'xl'} referrerPolicy="no-referrer" name={user.current?.user?.name} />
                            </Center>
                            <br />
                            <Center>
                                <Text as="b" fontSize="1xs" wordBreak="break-word" textAlign="center" maxW="90%">
                                    {user.current?.user?.name}
                                </Text>
                                <br />
                            </Center>
                            <Center>
                                <Text noOfLines={1} fontSize="1xs">
                                    {user.current?.user?.email}
                                </Text>
                            </Center>
                            <MenuDivider />
                            <MenuItem
                                onClick={() => {
                                    const queryString = new URLSearchParams({
                                        realm: process.env.REACT_APP_AUTH_REALM ?? '',
                                        client: 'profile',
                                    }).toString();
                                    window.open(
                                        `${process.env.REACT_APP_AUTH_WEB_URL}/profile?${queryString}`,
                                        '_blank'
                                    );
                                }}
                            >
                                Perfil
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={openModalLogout}>Sair</MenuItem>
                        </MenuList>
                    </Menu>
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav navItens={navBarItensRef.current} />
            </Collapse>

            {propDialog && <Dialog {...propDialog} />}
        </Box>
    );
}

interface IDesktopNavProps {
    navItens: Array<NavItem>;
}

const DesktopNav: FC<IDesktopNavProps> = ({ navItens }) => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4}>
            {navItens.map((navItem) => (
                <Box key={navItem.label} h="100%">
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Heading
                                p={2}
                                fontSize={'ml'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}
                                cursor="pointer"
                            >
                                {!!navItem?.href ? (
                                    <RouteLink to={navItem?.href ?? ''}>{navItem.label}</RouteLink>
                                ) : (
                                    navItem.label
                                )}
                            </Heading>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}
                            >
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <RouteLink key={child.label} to={child?.href ?? ''}>
                                            <DesktopSubNav {...child} href="#" />
                                        </RouteLink>
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <Box
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
        >
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text transition={'all .3s ease'} _groupHover={{ color: 'pink.400' }} fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}
                >
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Box>
    );
};

interface IMobileNavProps {
    navItens: Array<NavItem>;
}

const MobileNav: FC<IMobileNavProps> = ({ navItens }) => {
    return (
        <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
            {navItens.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                >
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    profile?: string;
    href?: string;
}

function navBarItens(isAdmin: boolean): Array<NavItem> {
    const nav: Array<NavItem> = [];
    nav.push({
        label: 'Mídias',
        href: '/media',
    });
    nav.push({
        label: 'Cadastros',
        children: [
            {
                label: 'Autores',
                subLabel: 'Cadastro de autores',
                href: '/authors',
            },
            {
                label: 'Editoras',
                subLabel: 'Cadastro de editoras',
                href: '/publishers',
            },
            {
                label: 'Classificações',
                subLabel: 'Cadastro de classificações',
                href: '/ratings',
            },
            {
                label: 'Proprietários',
                subLabel: 'Cadastro de proprietários',
                href: '/owners',
            },
            {
                label: 'Formatos de Publicação',
                subLabel: 'Cadastro de formato de publicação',
                href: '/publicationFormats',
            },
        ],
    });
    if (isAdmin) {
        nav.push({
            label: 'Usuários',
            children: [
                {
                    label: 'Usuários',
                    subLabel: 'Usuários do sistema',
                    href: '/users',
                },
            ],
        });
    }

    return nav;
}
