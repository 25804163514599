import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export interface IAuthor {
    id: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetAuthorsRequest extends IPaginateRequest {
    search?: string | null;
    order?: string | null;
    deleted?: boolean;
}

export interface IGetAuthorsResponse extends IPaginateResponse<IAuthor> {}

export interface IDeleteAuthorResponse {
    message: string;
}

export interface IReactivateAuthorResponse {
    message: string;
}

export interface IReprocessBalanceResponse {
    message: string;
}

export interface ICreateAuthorRequest {
    name: string;
}

export interface ICreateAuthorResponse extends IAuthor {}

export interface IUpdateAuthorRequest {
    name: string;
}

export interface IUpdateAuthorResponse extends IAuthor {}

async function getAuthors(param: IGetAuthorsRequest): Promise<IGetAuthorsResponse> {
    const response = await Api.get('/v1/authors', param);
    return response.data;
}

async function getAuthor(id: string): Promise<IAuthor> {
    const response = await Api.get(`/v1/authors/${id}`);
    return response.data;
}

async function createAuthor(param: ICreateAuthorRequest): Promise<ICreateAuthorResponse> {
    const response = await Api.post('/v1/authors', param);
    return response.data;
}

async function updateAuthor(id: string, param: IUpdateAuthorRequest): Promise<IUpdateAuthorResponse> {
    const response = await Api.put(`/v1/authors/${id}`, param);
    return response.data;
}

async function deleteAuthor(id: string): Promise<IDeleteAuthorResponse> {
    const response = await Api.delete(`/v1/authors/${id}`);
    return response.data;
}

async function reactivateAuthor(id: string): Promise<IReactivateAuthorResponse> {
    const response = await Api.post(`/v1/authors/reactivate/${id}`);
    return response.data;
}

export default {
    getAuthors,
    getAuthor,
    createAuthor,
    updateAuthor,
    deleteAuthor,
    reactivateAuthor,
};
