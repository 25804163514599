import { useRef } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useDisclosure,
} from '@chakra-ui/react';

export interface IDialogProps {
    title: string;
    message: string;
    onOk: () => void;
    onClose: () => void;
}

const Dialog: React.FC<IDialogProps> = (props) => {
    const cancelRef = useRef<any>();
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    function onCloseModal() {
        onClose();
        props.onClose();
    }

    function onOk() {
        props.onOk();
        onCloseModal();
    }

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCloseModal}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {props.title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{props.message}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onCloseModal}>
                            Cancelar
                        </Button>
                        <Button colorScheme="red" onClick={onOk} ml={3}>
                            Ok
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default Dialog;
