import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';
import { IAuthor } from './AuthorsSvc';
import { IOwner } from './OwnersSvc';
import { IPublicationFormat } from './PublicationFormatsSvc';
import { IPublisher } from './PublishersSvc';
import { IRating } from './RatingsSvc';

export interface IMedia {
    id: string;
    title: string;
    acquisition?: string;
    code?: number | null;
    year?: number | null;
    page?: number | null;
    cdd: string;
    cdu: string;
    utt: string;
    isbn: string;
    volume: string;
    edition: string;
    issues: number | null;
    keyword: string;
    pha: string;
    local: string;
    ownerId: string;
    owner: IOwner;
    publisherId: string;
    publisher: IPublisher;
    ratingId: string;
    rating: IRating;
    publicationFormatId: string;
    publicationFormat: IPublicationFormat;
    authors: IAuthor[];
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetMediasRequest extends IPaginateRequest {
    deleted?: boolean;
    authorId?: string | null;
    title?: string | null;
    code?: string | null;
    order?: string | null;
}

export interface IGetMediasResponse extends IPaginateResponse<IMedia> {}

export interface IDeleteMediaResponse {
    message: string;
}

export interface IReactivateMediaResponse {
    message: string;
}

export interface ICreateMediaRequest {
    title: string;
    acquisition: string | null;
    code?: number | null;
    year?: number | null;
    page?: number | null;
    cdd: string;
    cdu: string;
    utt: string;
    isbn: string;
    volume: string;
    edition: string;
    issues?: number | null;
    keyword: string;
    pha: string;
    local: string;
    ownerId: string;
    publisherId: string;
    ratingId: string;
    publicationFormatId: string;
    authors: string[];
}

export interface ICreateMediaResponse extends IMedia {}

export interface IUpdateMediaRequest {
    title: string;
    acquisition: string | null;
    code?: number | null;
    year?: number | null;
    page?: number | null;
    cdd: string;
    cdu: string;
    utt: string;
    isbn: string;
    volume: string;
    edition: string;
    issues?: number | null;
    keyword: string;
    pha: string;
    local: string;
    ownerId: string;
    publisherId: string;
    ratingId: string;
    publicationFormatId: string;
    authors: string[];
}

export interface IUpdateMediaResponse extends IMedia {}

async function getMedias(param: IGetMediasRequest): Promise<IGetMediasResponse> {
    const response = await Api.get('/v1/media', param);
    return response.data;
}

async function getMedia(id: string): Promise<IMedia> {
    const response = await Api.get(`/v1/media/${id}`);
    return response.data;
}

async function createMedia(param: ICreateMediaRequest): Promise<ICreateMediaResponse> {
    const response = await Api.post('/v1/media', param);
    return response.data;
}

async function updateMedia(id: string, param: IUpdateMediaRequest): Promise<IUpdateMediaResponse> {
    const response = await Api.put(`/v1/media/${id}`, param);
    return response.data;
}

async function deleteMedia(id: string): Promise<IDeleteMediaResponse> {
    const response = await Api.delete(`/v1/media/${id}`);
    return response.data;
}

async function reactivateMedia(id: string): Promise<IReactivateMediaResponse> {
    const response = await Api.post(`/v1/media/reactivate/${id}`);
    return response.data;
}

export default {
    getMedias,
    getMedia,
    createMedia,
    updateMedia,
    deleteMedia,
    reactivateMedia,
};
