import axios from 'axios';
import AuthSvc from '../services/AuthSvc';
import Session from '../utils/session/Session';

const Axios = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    timeout: 30000,
});

Axios.interceptors.request.use(
    async (config) => {
        const token = Session.getAccessToken();

        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error?.response?.status === 401) {
            const refreshToken = Session.getRefreshToken();
            if (refreshToken) {
                try {
                    const originalRequest = error.config;
                    const response = await AuthSvc.refreshToken(refreshToken);
                    Session.set(response.accessToken, response.refreshToken, Session.get()?.remember ?? false);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.accessToken;
                    return Axios(originalRequest);
                } catch (error) {
                    Session.logout(true);
                }
            }
            Session.logout(true);
        }
        return Promise.reject(error);
    }
);

export default Axios;
