import decode from 'jwt-decode';

export interface ISession {
    user: JwtToken;
    remember: boolean;
    accessToken: string;
    refreshToken: string;
    expireTokenAt: Date;
}

export interface JwtToken {
    email: string;
    exp: number;
    iat: number;
    id: string;
    name: string;
    picture: string;
    realm: string;
    clients: Array<{
        code: string;
        roles: string[];
    }>;
}

function set(accessToken: string, refreshToken: string, remember: boolean) {
    clear();

    try {
        const payload = decode<JwtToken>(accessToken);
        if (payload.realm !== process.env.REACT_APP_AUTH_REALM) {
            throw new Error('Usuário sem acesso ao reino');
        }
        let existClient = false;
        let existRole = false;
        for (const client of payload.clients) {
            if (client.code === process.env.REACT_APP_AUTH_CLIENT) {
                existClient = true;
                if (client.roles.length > 0) {
                    existRole = true;
                }
                if (remember) {
                    window.localStorage.setItem('@roles', JSON.stringify(client.roles));
                } else {
                    window.sessionStorage.setItem('@roles', JSON.stringify(client.roles));
                }
                break;
            }
        }
        if (!existClient || !existRole) {
            throw new Error('Usuário sem acesso a aplicação');
        }
        const session: ISession = {
            user: payload,
            remember,
            accessToken,
            refreshToken,
            expireTokenAt: new Date(payload.exp * 1000),
        };
        if (remember) {
            window.localStorage.setItem('@session', JSON.stringify(session));
        } else {
            window.sessionStorage.setItem('@session', JSON.stringify(session));
        }
    } catch (error) {
        throw new Error(error);
    }
}

function clear() {
    window.localStorage.removeItem('@session');
    window.sessionStorage.removeItem('@session');
    window.sessionStorage.removeItem('@roles');
}

function get(): ISession | null {
    let token = null;
    const tokenLocal = window.localStorage.getItem('@session');
    const tokenSession = window.sessionStorage.getItem('@session');
    if (tokenLocal) {
        token = JSON.parse(tokenLocal);
    } else if (tokenSession) {
        token = JSON.parse(tokenSession);
    }
    return token ?? null;
}

function getRoles(): string[] {
    const rolesStringLocal = window.localStorage.getItem('@roles');
    const rolesStringSession = window.sessionStorage.getItem('@roles');
    if (!!rolesStringLocal || !!rolesStringSession) {
        return JSON.parse(rolesStringLocal ?? rolesStringSession ?? '[]');
    }
    return [];
}

function getAccessToken(): string {
    const session = get();
    if (session) {
        return session.accessToken;
    } else {
        return '';
    }
}

function getRefreshToken(): string {
    const session = get();
    if (session) {
        return session.refreshToken;
    } else {
        return '';
    }
}

function expiredToken(): boolean {
    const session = get();
    if (session) {
        return new Date(session.expireTokenAt).getTime() < new Date().getTime();
    } else {
        return true;
    }
}

function isAdmin() {
    const roles = getRoles();
    return roles.includes('admin') || roles.includes('sys-admin');
}

function isSysAdmin() {
    const roles = getRoles();
    return roles.includes('sys-admin');
}

function logout(rememberUrl: boolean = true) {
    clear();
    const query: any = {
        realm: process.env.REACT_APP_AUTH_REALM ?? '',
        client: process.env.REACT_APP_AUTH_CLIENT ?? '',
        redirect_uri: rememberUrl ? window.location.href : `${window.location.origin}/console`,
    };
    const queryString = new URLSearchParams(query).toString();
    window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/login?${queryString}`;
}

export default {
    set,
    clear,
    get,
    isAdmin,
    isSysAdmin,
    getAccessToken,
    getRefreshToken,
    expiredToken,
    logout,
    getRoles,
};
