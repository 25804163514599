import {
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    VStack,
    Text,
    InputRightElement,
    useDisclosure,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';

import MediasSvc, { IMedia } from '../../services/MediasSvc';
import { IOwner } from '../../services/OwnersSvc';
import { IPublisher } from '../../services/PublishersSvc';
import SelectOwner from '../../components/select/SelectOwner';
import SelectPublisher from '../../components/select/SelectPublishers';
import { IPublicationFormat } from '../../services/PublicationFormatsSvc';
import SelectRating from '../../components/select/SelectRating';
import SelectPublicationFormat from '../../components/select/SelectPublicationFormat';
import { IRating } from '../../services/RatingsSvc';
import NumberUtils from '../../utils/value/NumberUtils';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { IAuthor } from '../../services/AuthorsSvc';
import ModalAuthor from '../../components/modal/ModalAuthor';

interface IEditMediaModalProps {
    isOpen: boolean;
    onClose: () => void;
    media: IMedia | null;
    fetchMedias: (reset: boolean) => void;
}

const EditMediaModal: FC<IEditMediaModalProps> = ({ isOpen, onClose, media, fetchMedias }) => {
    const toast = useToast();
    const { isOpen: isOpenModalAuthor, onOpen: onOpenModalAuthor, onClose: onCloseModalAuthor } = useDisclosure();
    const [acquisitionDate, setAcquisitionDate] = useState(
        media?.acquisition ? media.acquisition.substring(0, 10) : ''
    );
    const [title, setTitle] = useState(media?.title ?? '');
    const [volume, setVolume] = useState(media?.volume ?? '');
    const [cdd, setCdd] = useState(media?.cdd ?? '');
    const [cdu, setCdu] = useState(media?.cdu ?? '');
    const [utt, setUtt] = useState(media?.utt ?? '');
    const [isbn, setIsbn] = useState(media?.isbn ?? '');
    const [edition, setEdition] = useState(media?.edition ?? '');
    const [keyword, setKeyword] = useState(media?.keyword ?? '');
    const [pha, setPha] = useState(media?.pha ?? '');
    const [local, setLocal] = useState(media?.local ?? '');
    const [code, setCode] = useState(media?.code ?? null);
    const [year, setYear] = useState(media?.year ?? null);
    const [issues, setIssues] = useState(media?.issues ?? null);
    const [page, setPage] = useState(media?.page ?? null);
    const [erros, setErros] = useState<any>({});
    const [saving, setSaving] = useState(false);
    const [owner, setOwner] = useState<IOwner | null>(media?.owner ?? null);
    const [publisher, setPublisher] = useState<IPublisher | null>(media?.publisher ?? null);
    const [publicationFormat, setPublicationFormat] = useState<IPublicationFormat | null>(
        media?.publicationFormat ?? null
    );
    const [rating, setRating] = useState<IRating | null>(media?.rating ?? null);
    const [authors, setAuthors] = useState<IAuthor[]>(media?.authors ?? []);

    async function saveMedia() {
        try {
            setErros({});
            const schema = yup.object().shape({
                title: yup.string().required('Título não foi informado'),
                ownerId: yup.string().required('Proprietário não foi informado'),
                publicationFormatId: yup.string().required('Formato de publicação não foi informado'),
                publisherId: yup.string().required('Editora não foi informada'),
                ratingId: yup.string().required('Classificação não foi informada'),
                authors: yup.array().min(1, 'Informe pelo menos um autor'),
            });
            schema.validateSync(
                {
                    title,
                    ownerId: owner?.id,
                    publicationFormatId: publicationFormat?.id,
                    publisherId: publisher?.id,
                    ratingId: rating?.id,
                    authors,
                },
                { abortEarly: false }
            );
            setSaving(true);

            if (!media) {
                await MediasSvc.createMedia({
                    title,
                    acquisition: acquisitionDate.trim() === '' ? null : acquisitionDate,
                    cdd,
                    cdu,
                    utt,
                    isbn,
                    volume,
                    edition,
                    keyword,
                    pha,
                    local,
                    code: code ?? 0,
                    year: year ?? 0,
                    page: page ?? 0,
                    issues: issues ?? 0,
                    ownerId: owner?.id ?? '',
                    publisherId: publisher?.id ?? '',
                    ratingId: rating?.id ?? '',
                    publicationFormatId: publicationFormat?.id ?? '',
                    authors: authors.map((it) => it.id),
                });
            } else {
                await MediasSvc.updateMedia(media?.id ?? '', {
                    title,
                    acquisition: acquisitionDate.trim() === '' ? null : acquisitionDate.trim(),
                    cdd,
                    cdu,
                    utt,
                    isbn,
                    volume,
                    edition,
                    keyword,
                    pha,
                    local,
                    code: code ?? null,
                    year: year ?? null,
                    page: page ?? null,
                    issues: issues ?? null,
                    ownerId: owner?.id ?? '',
                    publisherId: publisher?.id ?? '',
                    ratingId: rating?.id ?? '',
                    publicationFormatId: publicationFormat?.id ?? '',
                    authors: authors.map((it) => it.id),
                });
            }
            toast({
                description: 'Mídia salva com sucesso!',
                duration: 5000,
                status: 'success',
            });
            setSaving(false);
            fetchMedias(true);
            onClose();
        } catch (error) {
            setSaving(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar mídia',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    function removeAuthor(index: number) {
        setAuthors((val) => val.filter((_, i) => i !== index));
    }

    function addAuthor(item: IAuthor) {
        const exists = authors.some((val) => val.id === item.id);
        if (exists) {
            return toast({
                description: 'O autor já está incluído na lista!',
                duration: 5000,
                status: 'error',
            });
        }
        setAuthors([...authors, item]);
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} size="6xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{media ? 'Editar mídia' : 'Nova mídia'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack>
                        <VStack p={5} alignItems="flex-start">
                            <HStack width="100%">
                                <FormControl isRequired isInvalid={!!erros['title']}>
                                    <FormLabel>Título</FormLabel>
                                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                                    {!!erros['title'] && <FormErrorMessage>{erros['title']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['code']}>
                                    <FormLabel>Código</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            value={code ?? ''}
                                            onChange={() => {}}
                                            onKeyDown={(e) => {
                                                const val = NumberUtils.inputKeyMoney(code ?? 0, e.nativeEvent.key);
                                                setCode(val);
                                            }}
                                            onPaste={(e) => {
                                                const val = NumberUtils.formatValueOnPaste(
                                                    e.clipboardData.getData('Text')
                                                );
                                                setCode(val);
                                            }}
                                        />
                                    </InputGroup>
                                    {!!erros['code'] && <FormErrorMessage>{erros['code']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['acquisitionDate']}>
                                    <FormLabel>Data da aquisição</FormLabel>
                                    <Input
                                        value={acquisitionDate}
                                        onChange={(e) => setAcquisitionDate(e.target.value)}
                                        type="date"
                                    />
                                    {!!erros['acquisitionDate'] && (
                                        <FormErrorMessage>{erros['acquisitionDate']}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </HStack>
                            <HStack width="100%">
                                <FormControl isRequired isInvalid={!!erros['ownerId']}>
                                    <FormLabel>Proprietário</FormLabel>
                                    <SelectOwner
                                        placeholder="Selecione um proprietário"
                                        value={owner}
                                        onChange={(e) => setOwner(e)}
                                    />
                                    {!!erros['ownerId'] && <FormErrorMessage>{erros['ownerId']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isRequired isInvalid={!!erros['publisherId']}>
                                    <FormLabel>Editora</FormLabel>
                                    <SelectPublisher
                                        placeholder="Selecione uma editora"
                                        value={publisher}
                                        onChange={(e) => setPublisher(e)}
                                    />
                                    {!!erros['publisherId'] && (
                                        <FormErrorMessage>{erros['publisherId']}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </HStack>
                            <HStack width="100%">
                                <FormControl isRequired isInvalid={!!erros['publicationFormatId']}>
                                    <FormLabel>Formato de Publicação</FormLabel>
                                    <SelectPublicationFormat
                                        placeholder="Selecione um formato"
                                        value={publicationFormat}
                                        onChange={(e) => setPublicationFormat(e)}
                                    />
                                    {!!erros['publicationFormatId'] && (
                                        <FormErrorMessage>{erros['publicationFormatId']}</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl isRequired isInvalid={!!erros['ratingId']}>
                                    <FormLabel>Classificação</FormLabel>
                                    <SelectRating
                                        placeholder="Selecione uma classificação"
                                        value={rating}
                                        onChange={(e) => setRating(e)}
                                    />
                                    {!!erros['ratingId'] && <FormErrorMessage>{erros['ratingId']}</FormErrorMessage>}
                                </FormControl>
                            </HStack>
                            <HStack width="100%">
                                <FormControl isInvalid={!!erros['volume']}>
                                    <FormLabel>Volume</FormLabel>
                                    <Input value={volume} onChange={(e) => setVolume(e.target.value)} />
                                    {!!erros['volume'] && <FormErrorMessage>{erros['volume']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['cdd']}>
                                    <FormLabel>CDD</FormLabel>
                                    <Input value={cdd} onChange={(e) => setCdd(e.target.value)} />
                                    {!!erros['cdd'] && <FormErrorMessage>{erros['cdd']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['cdu']}>
                                    <FormLabel>CDU</FormLabel>
                                    <Input value={cdu} onChange={(e) => setCdu(e.target.value)} />
                                    {!!erros['cdu'] && <FormErrorMessage>{erros['cdu']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['utt']}>
                                    <FormLabel>UTT</FormLabel>
                                    <Input value={utt} onChange={(e) => setUtt(e.target.value)} />
                                    {!!erros['utt'] && <FormErrorMessage>{erros['utt']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['isbn']}>
                                    <FormLabel>ISBN</FormLabel>
                                    <Input value={isbn} onChange={(e) => setIsbn(e.target.value)} />
                                    {!!erros['isbn'] && <FormErrorMessage>{erros['isbn']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['pha']}>
                                    <FormLabel>PHA</FormLabel>
                                    <Input value={pha} onChange={(e) => setPha(e.target.value)} />
                                    {!!erros['pha'] && <FormErrorMessage>{erros['pha']}</FormErrorMessage>}
                                </FormControl>
                            </HStack>
                            <HStack width="100%">
                                <FormControl isInvalid={!!erros['edition']}>
                                    <FormLabel>Edição</FormLabel>
                                    <Input value={edition} onChange={(e) => setEdition(e.target.value)} />
                                    {!!erros['edition'] && <FormErrorMessage>{erros['edition']}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!erros['local']}>
                                    <FormLabel>Local</FormLabel>
                                    <Input value={local} onChange={(e) => setLocal(e.target.value)} />
                                    {!!erros['local'] && <FormErrorMessage>{erros['local']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['year']}>
                                    <FormLabel>Ano</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            value={year ?? ''}
                                            onChange={() => {}}
                                            onKeyDown={(e) => {
                                                const val = NumberUtils.inputKeyMoney(year ?? 0, e.nativeEvent.key);
                                                setYear(val);
                                            }}
                                            onPaste={(e) => {
                                                const val = NumberUtils.formatValueOnPaste(
                                                    e.clipboardData.getData('Text')
                                                );
                                                setYear(val);
                                            }}
                                        />
                                    </InputGroup>
                                    {!!erros['year'] && <FormErrorMessage>{erros['year']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['issues']}>
                                    <FormLabel>Issue</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            value={issues ?? ''}
                                            onChange={() => {}}
                                            onKeyDown={(e) => {
                                                const val = NumberUtils.inputKeyMoney(issues ?? 0, e.nativeEvent.key);
                                                setIssues(val);
                                            }}
                                            onPaste={(e) => {
                                                const val = NumberUtils.formatValueOnPaste(
                                                    e.clipboardData.getData('Text')
                                                );
                                                setIssues(val);
                                            }}
                                        />
                                    </InputGroup>
                                    {!!erros['issues'] && <FormErrorMessage>{erros['issues']}</FormErrorMessage>}
                                </FormControl>
                                <FormControl isInvalid={!!erros['page']}>
                                    <FormLabel>Páginas</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            value={page ?? ''}
                                            onChange={() => {}}
                                            onKeyDown={(e) => {
                                                const val = NumberUtils.inputKeyMoney(page ?? 0, e.nativeEvent.key);
                                                setPage(val);
                                            }}
                                            onPaste={(e) => {
                                                const val = NumberUtils.formatValueOnPaste(
                                                    e.clipboardData.getData('Text')
                                                );
                                                setPage(val);
                                            }}
                                        />
                                    </InputGroup>
                                    {!!erros['issues'] && <FormErrorMessage>{erros['issues']}</FormErrorMessage>}
                                </FormControl>
                            </HStack>

                            <HStack width="100%">
                                <FormControl isInvalid={!!erros['keyword']}>
                                    <FormLabel>Palavras-chave</FormLabel>
                                    <Input value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                    {!!erros['keyword'] && <FormErrorMessage>{erros['keyword']}</FormErrorMessage>}
                                </FormControl>
                            </HStack>

                            <HStack width="100%">
                                <Divider marginTop={15} marginBottom={15} />
                            </HStack>
                            <Text fontSize="2xl">Autores</Text>
                            <VStack width="100%" alignItems="flex-start">
                                {authors.map((it, index) => (
                                    <FieldSelectAuthors
                                        key={it.id ?? index}
                                        it={it}
                                        index={index}
                                        removeAuthor={removeAuthor}
                                    />
                                ))}
                            </VStack>
                            <HStack width="100%">
                                <Button onClick={() => onOpenModalAuthor()} width="20%" size="xs">
                                    Adicionar <AddIcon color="white.500" marginLeft={3} />
                                </Button>
                            </HStack>
                            {!!erros['authors'] && <Text color="red.300">{erros['authors']}</Text>}
                            {!!media && (
                                <>
                                    <HStack width="100%">
                                        <Divider marginTop={15} marginBottom={15} />
                                    </HStack>
                                    <HStack width="25%">
                                        <FormControl isReadOnly>
                                            <FormLabel>Criado em</FormLabel>
                                            <Input value={new Date(media.createdAt).toLocaleString()} />
                                        </FormControl>
                                    </HStack>
                                </>
                            )}
                        </VStack>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={saveMedia} colorScheme="blue" isLoading={saving}>
                        Salvar
                    </Button>
                </ModalFooter>
            </ModalContent>
            {isOpen && (
                <ModalAuthor
                    isOpen={isOpenModalAuthor}
                    onClose={onCloseModalAuthor}
                    onSelected={addAuthor}
                    placeholder="Selecione um autor"
                />
            )}
        </Modal>
    );
};

interface IFieldSelectAuthorsProps {
    it: IAuthor;
    index: number;
    removeAuthor: (index: number) => void;
}

function FieldSelectAuthors({ it, index, removeAuthor }: IFieldSelectAuthorsProps) {
    return (
        <HStack key={it.id ?? index} width="40%">
            <FormControl>
                <InputGroup>
                    <Input value={it.name} />
                    <InputRightElement width="3rem">
                        <Button onClick={() => removeAuthor(index)}>
                            <CloseIcon color="red.500" />
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
        </HStack>
    );
}

export default EditMediaModal;
