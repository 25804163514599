import {
    Avatar,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Select,
    Spinner,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import UsersSvc, { IUser } from '../../services/UsersSvc';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';
import Session from '../../utils/session/Session';

const EditUserPage: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams();
    const isNewRef = useRef(id === 'new');
    const idRef = useRef(id);
    const loadingRef = useRef(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [profile, setProfile] = useState('');
    const [erros, setErros] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<IUser | null>(null);

    async function createUser() {
        try {
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
                email: yup.string().required('E-mail é obrigatório').email('E-mail inválido'),
                profile: yup.string().required('Perfil é obrigatório'),
            });
            schema.validateSync({ name, email, profile }, { abortEarly: false });
            setLoading(true);

            const response = await UsersSvc.createUser({ name, email, profile });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchUser();
            navigate(`/users/${response.id}`, {
                replace: true,
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar usuário',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    async function updateUser() {
        try {
            if (!user) {
                return;
            }
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
                profile: yup.string().required('Perfil é obrigatório'),
            });
            schema.validateSync({ name, profile }, { abortEarly: false });

            setLoading(true);
            const response = await UsersSvc.updateUser(user.id, { name, profile });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchUser();
            toast({
                description: 'Usuário salvo com sucesso!',
                duration: 1000,
                status: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar usuário',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    const fetchUser = useCallback(async () => {
        try {
            if (!idRef.current || isNewRef.current || loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await UsersSvc.getUser(idRef.current);
            setUser(response);
            setName(response.name);
            setEmail(response.email);
            setProfile(response.profile);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <Stack>
            <BreadcrumbCustom
                options={[
                    {
                        name: 'Usuários',
                        route: '/users',
                    },
                    {
                        name: isNewRef.current ? 'Novo Usuário' : 'Editar Usuário',
                        route: '',
                        tooltip: isNewRef.current ? undefined : idRef.current,
                    },
                ]}
            />
            <HStack width="25%" justifyContent="center">
                {loading && <Spinner />}
            </HStack>
            <VStack p={5} alignItems="flex-start">
                {!!user && (
                    <HStack width="25%" justifyContent="center">
                        <Avatar size="xl" referrerPolicy="no-referrer" name={name} />
                    </HStack>
                )}

                <HStack width="25%">
                    <FormControl isRequired isInvalid={!!erros['name']}>
                        <FormLabel>Nome</FormLabel>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                        {!!erros['name'] && <FormErrorMessage>{erros['name']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                <HStack width="25%">
                    <FormControl
                        isReadOnly={!isNewRef.current}
                        isRequired={isNewRef.current}
                        isInvalid={!!erros['email']}
                    >
                        <FormLabel>E-mail</FormLabel>
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                        {!!erros['email'] && <FormErrorMessage>{erros['email']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                <HStack width="25%">
                    <FormControl isReadOnly={!isNewRef.current} isRequired isInvalid={!!erros['profile']}>
                        <FormLabel>Perfil</FormLabel>
                        <Select placeholder="-" value={profile} onChange={(e) => setProfile(e.target.value)}>
                            {Session.isSysAdmin() && <option value="sys-admin">Administrador de Sistema</option>}
                            <option value="admin">Administrador</option>
                            <option value="librarian">Bibliotecário</option>
                        </Select>
                        {!!erros['profile'] && <FormErrorMessage>{erros['profile']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                {!!user && (
                    <>
                        <HStack width="25%">
                            <FormControl isReadOnly>
                                <FormLabel>E-mail verificado?</FormLabel>
                                <Input value={user.verifiedEmail ? 'Sim' : 'Não'} />
                            </FormControl>
                        </HStack>
                        <HStack width="25%">
                            <FormControl isReadOnly>
                                <FormLabel>Criado em</FormLabel>
                                <Input value={new Date(user.createdAt).toLocaleString()} />
                            </FormControl>
                        </HStack>
                        {!!user.deletedAt && (
                            <HStack width="25%">
                                <FormControl isReadOnly>
                                    <FormLabel>Deletado em</FormLabel>
                                    <Input value={new Date(user.deletedAt).toLocaleString()} />
                                </FormControl>
                            </HStack>
                        )}
                    </>
                )}

                <HStack paddingTop={3} width="25%">
                    <Button onClick={user ? updateUser : createUser} colorScheme="blue">
                        Salvar
                    </Button>
                </HStack>
            </VStack>
        </Stack>
    );
};

export default EditUserPage;
