import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export interface IUser {
    id: string;
    name: string;
    email: string;
    verifiedEmail: boolean;
    profile: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetUsersRequest extends IPaginateRequest {
    search?: string | null;
    deleted?: boolean;
}

export interface IGetUsersResponse extends IPaginateResponse<IUser> {}

export interface IDeleteUserResponse {
    message: string;
}

export interface IReactivateUserResponse {
    message: string;
}

export interface ICreateUserRequest {
    name: string;
    email: string;
    profile: string;
}

export interface ICreateUserResponse extends IUser {}

export interface IUpdateUserRequest {
    name: string;
    profile: string;
}

export interface IUpdateUserResponse extends IUser {}

async function getUsers(param: IGetUsersRequest): Promise<IGetUsersResponse> {
    const response = await Api.get('/v1/users', param);
    return response.data;
}

async function getUser(id: string): Promise<IUser> {
    const response = await Api.get(`/v1/users/${id}`);
    return response.data;
}

async function createUser(param: ICreateUserRequest): Promise<ICreateUserResponse> {
    const response = await Api.post('/v1/users', param);
    return response.data;
}

async function updateUser(id: string, param: IUpdateUserRequest): Promise<IUpdateUserResponse> {
    const response = await Api.put(`/v1/users/${id}`, param);
    return response.data;
}

async function deleteUser(id: string): Promise<IDeleteUserResponse> {
    const response = await Api.delete(`/v1/users/${id}`);
    return response.data;
}

async function reactivateUser(id: string): Promise<IReactivateUserResponse> {
    const response = await Api.post(`/v1/users/reactivate/${id}`);
    return response.data;
}

export default {
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    reactivateUser,
};
