import { LockIcon } from '@chakra-ui/icons';
import { Flex, Text, useColorModeValue, Spinner, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AuthSvc from '../../services/AuthSvc';
import Session from '../../utils/session/Session';

interface IAuthPageSearchParams {
    authorizationToken: string;
    redirectUri: string;
    remember: boolean;
}

function validUrlParams(params: IAuthPageSearchParams) {
    if (!params.authorizationToken) {
        return 'URL para login inválida';
    }
    return null;
}

export default function AuthPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchParamsRef = useRef<IAuthPageSearchParams>({
        authorizationToken: searchParams.get('authorization_token') ?? '',
        redirectUri: searchParams.get('redirect_uri') ?? '',
        remember: searchParams.get('remember') === 'true',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchSession = useCallback(async () => {
        try {
            const errParams = validUrlParams(searchParamsRef.current);
            if (errParams) {
                setLoading(false);
                setError(errParams);
                return;
            }
            setLoading(true);
            const response = await AuthSvc.getToken({ authorizationToken: searchParamsRef.current.authorizationToken });
            Session.set(response.accessToken, response.refreshToken, searchParamsRef.current.remember);
            setLoading(false);
            let url = '/';
            if (!!searchParamsRef.current?.redirectUri) {
                url = new URL(searchParamsRef.current?.redirectUri).pathname;
            }
            navigate(url, {
                replace: true,
            });
        } catch (error) {
            setLoading(false);
            setError(error.message);
            const sleep = () => new Promise((resolve) => setTimeout(resolve, 1000));
            for (let i = 10; i >= 0; i--) {
                setError(`${error.message}... ${i}`);
                await sleep();
            }
            Session.logout(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchSession();
    }, [fetchSession]);

    return (
        <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
            {loading && <Spinner size="lg" />}
            {!!error && (
                <HStack>
                    <LockIcon fontSize="2xl" color="gray.300" />
                    <Text fontSize="2xl" color={'gray.400'}>
                        {error}
                    </Text>
                </HStack>
            )}
        </Flex>
    );
}
