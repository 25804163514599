import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import HeaderMenu from './HeaderMenu';

import UsersListPage from '../users/UsersListPage';
import EditUserPage from '../users/EditUserPage';
import MediasListPage from '../media/MediasListPage';
import EditAuthorPage from '../author/EditAuthorPage';
import AuthorsListPage from '../author/AuthorsListPage';
import Session from '../../utils/session/Session';
import PublishersListPage from '../publisher/PublishersListPage';
import EditPublisherPage from '../publisher/EditPublisherPage';
import RatingsListPage from '../rating/RatingsListPage';
import EditRatingPage from '../rating/EditRatingPage';
import OwnersListPage from '../owner/OwnersListPage';
import EditOwnerPage from '../owner/EditOwnerPage';
import EditPublicationFormatPage from '../publicationFormats/EditPublicationFormatPage';
import PublicationFormatsListPage from '../publicationFormats/PublicationFormatsListPage';
import AuthSvc from '../../services/AuthSvc';

export default function MainPage() {
    const isAdminRef = useRef(Session.isAdmin());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const refreshToken = useCallback(async () => {
        try {
            setLoading(true);
            if (Session.expiredToken()) {
                try {
                    const refreshToken = Session.getRefreshToken();
                    const response = await AuthSvc.refreshToken(refreshToken);
                    Session.set(response.accessToken, response.refreshToken, Session.get()?.remember ?? false);
                } catch (error) {
                    if (error.status === 401) {
                        Session.logout(true);
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }, []);

    useEffect(() => {
        refreshToken();
    }, [refreshToken]);

    return (
        <Box>
            {loading || error ? (
                <Flex justifyContent="center" alignItems="center" width="100%" height="100vh">
                    {loading && <Spinner size="lg" />}
                    {!!error && <Text>{error}</Text>}
                </Flex>
            ) : (
                <>
                    <Flex position="fixed" width="100%" zIndex={1000}>
                        <HeaderMenu />
                    </Flex>
                    <Box paddingTop="60px" zIndex={-1}>
                        <Routes>
                            <Route path="/authors" element={<AuthorsListPage />} />
                            <Route path="/authors/:id" element={<EditAuthorPage />} />
                            <Route path="/publishers" element={<PublishersListPage />} />
                            <Route path="/publishers/:id" element={<EditPublisherPage />} />
                            <Route path="/ratings" element={<RatingsListPage />} />
                            <Route path="/ratings/:id" element={<EditRatingPage />} />
                            <Route path="/owners" element={<OwnersListPage />} />
                            <Route path="/owners/:id" element={<EditOwnerPage />} />
                            <Route path="/publicationFormats" element={<PublicationFormatsListPage />} />
                            <Route path="/publicationFormats/:id" element={<EditPublicationFormatPage />} />
                            <Route path="/media" element={<MediasListPage />} />
                            {isAdminRef.current && (
                                <>
                                    <Route path="/users" element={<UsersListPage />} />
                                    <Route path="/users/:id" element={<EditUserPage />} />
                                </>
                            )}
                            <Route path="/*" element={<Navigate to="/media" replace />} />
                        </Routes>
                    </Box>
                </>
            )}
        </Box>
    );
}
