import {
    Box,
    Button,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Tbody,
    Tfoot,
    useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Text, Stack, Td, Tr, Th, TableContainer, Thead, Table } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { RiSearch2Line } from 'react-icons/ri';

import OwnersSvc, { IOwner } from '../../services/OwnersSvc';
import Dialog, { IDialogProps } from '../../components/Dialog';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';

const OwnersListPage: FC = () => {
    const toast = useToast();
    const firstRunRef = useRef(true);
    const loadingRef = useRef(false);
    const setTimeoutRef = useRef<any>(null);
    const searchRef = useRef('');
    const totalLoadedRef = useRef(0);
    const totalRef = useRef(0);
    const pageRef = useRef(0);
    const tabIndexRef = useRef(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState<any>({});
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [owners, setOwners] = useState<IOwner[]>([]);
    const [propDialog, setPropDialog] = useState<IDialogProps | null>(null);

    const fetchOwners = useCallback(
        async (reset: boolean) => {
            try {
                if (
                    loadingRef.current ||
                    (totalLoadedRef.current >= totalRef.current && totalRef.current > 0 && !reset)
                ) {
                    return;
                }

                if (reset) {
                    pageRef.current = 0;
                    totalRef.current = 0;
                    totalLoadedRef.current = 0;
                    setOwners([]);
                }

                loadingRef.current = true;

                setLoading(true);
                const response = await OwnersSvc.getOwners({
                    page: pageRef.current + 1,
                    limit: 20,
                    search: searchRef.current,
                    deleted: tabIndexRef.current === 1,
                });
                if (reset) {
                    setOwners(response.result);
                } else {
                    setOwners((val) => {
                        const valCopy = [...val, ...response.result];
                        totalLoadedRef.current = valCopy.length;
                        return valCopy;
                    });
                }

                setTotal(response.total);
                pageRef.current = response.page;
                totalRef.current = response.total;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                loadingRef.current = false;
            }
        },
        [toast]
    );

    const deleteOrReactivateOwner = useCallback(
        async (owner: IOwner) => {
            try {
                setLoadingAction((val: any) => ({ ...val, [owner.id]: true }));
                const action = owner.deletedAt ? OwnersSvc.reactivateOwner : OwnersSvc.deleteOwner;
                const response = await action(owner.id);
                toast({
                    description: response?.message,
                    duration: 2000,
                    status: 'success',
                });
                fetchOwners(true);
            } catch (error) {
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                setLoadingAction((val: any) => {
                    let copy = { ...val };
                    delete copy[owner.id];
                    return copy;
                });
            }
        },
        [fetchOwners, toast]
    );

    const openModalDeleteOrReactivate = useCallback(
        (owner: IOwner) => {
            setPropDialog({
                title: 'Confirmação',
                message: `Realmente deseja ${owner.deletedAt ? 'restaurar' : 'deletar'} o proprietário "${
                    owner.name
                }"?`,
                onOk: () => deleteOrReactivateOwner(owner),
                onClose: () => setPropDialog(null),
            });
        },
        [deleteOrReactivateOwner]
    );

    useEffect(() => {
        function listenerScroll() {
            const totalHeight = document.body.scrollHeight;
            const offset = window.pageYOffset + window.innerHeight;
            if (offset >= totalHeight * 0.9 && !loadingRef.current) {
                fetchOwners(false);
            }
        }
        window.addEventListener('scroll', listenerScroll);
        return () => window.removeEventListener('scroll', listenerScroll);
    }, [fetchOwners]);

    useEffect(() => {
        if (firstRunRef.current) {
            fetchOwners(true);
            firstRunRef.current = false;
        }
    }, [fetchOwners]);

    return (
        <Stack>
            <BreadcrumbCustom
                options={[
                    {
                        name: 'Proprietário',
                        route: '/owners',
                    },
                ]}
            />
            <HStack paddingBottom={5} paddingLeft={5} paddingRight={5} justifyContent="space-between">
                <Tabs
                    onChange={(index) => {
                        setTabIndex(index);
                        tabIndexRef.current = index;
                        fetchOwners(true);
                    }}
                >
                    <TabList>
                        <Tab>Ativos</Tab>
                        <Tab>Deletados</Tab>
                    </TabList>
                </Tabs>

                <RouteLink to={`${window.location.pathname}/new`}>
                    <Button colorScheme="blue">Novo proprietário</Button>
                </RouteLink>
            </HStack>
            <HStack>
                <Box w="40%" p={5}>
                    <InputGroup size="md">
                        <Input
                            pr="4.5rem"
                            placeholder="Pesquisar..."
                            value={searchText}
                            onChange={(e) => {
                                clearTimeout(setTimeoutRef.current);
                                setTimeoutRef.current = setTimeout(() => {
                                    searchRef.current = e.target.value;
                                    fetchOwners(true);
                                }, 1000);
                                setSearchText(e.target.value);
                            }}
                        />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={() => fetchOwners(true)}>
                                <SearchIcon />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </Box>
            </HStack>
            <TableContainer paddingLeft={5} paddingRight={5}>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Nome</Th>
                            <Th>Criado em</Th>
                            {tabIndex === 1 && <Th>Deletado em</Th>}
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {owners.map((item) => (
                            <Tr key={item.id}>
                                <Td>{item.name}</Td>
                                <Td>{new Date(item.createdAt).toLocaleString()}</Td>
                                {item.deletedAt && <Td>{new Date(item.deletedAt).toLocaleString()}</Td>}
                                <Td textAlign="right">
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                            Ações
                                            {!!loadingAction[item.id] && <Spinner size="xs" marginLeft={2} />}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                onClick={() =>
                                                    toast({
                                                        description: item.id,
                                                        duration: 5000,
                                                        status: 'info',
                                                    })
                                                }
                                            >
                                                Ver Id
                                            </MenuItem>
                                            <RouteLink to={`${window.location.pathname}/${item.id}`}>
                                                <MenuItem>Editar</MenuItem>
                                            </RouteLink>
                                            <MenuItem onClick={() => openModalDeleteOrReactivate(item)}>
                                                {item.deletedAt ? 'Restaurar' : 'Deletar'}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th colSpan={7}>
                                {owners.length > 0 ? (
                                    <HStack justifyContent="center" p={5}>
                                        <Text>
                                            {owners.length} de {total} registros
                                        </Text>
                                        {loading && <Spinner />}
                                    </HStack>
                                ) : (
                                    <HStack justifyContent="center" p={5}>
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <>
                                                <Text>Nenhuma informação encontrada</Text>
                                                <RiSearch2Line size={15} />
                                            </>
                                        )}
                                    </HStack>
                                )}
                            </Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
            {propDialog && <Dialog {...propDialog} />}
        </Stack>
    );
};

export default OwnersListPage;
