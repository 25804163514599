import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export interface IOwner {
    id: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetOwnersRequest extends IPaginateRequest {
    search?: string | null;
    order?: string | null;
    deleted?: boolean;
}

export interface IGetOwnersResponse extends IPaginateResponse<IOwner> {}

export interface IDeleteOwnerResponse {
    message: string;
}

export interface IReactivateOwnerResponse {
    message: string;
}

export interface IReprocessBalanceResponse {
    message: string;
}

export interface ICreateOwnerRequest {
    name: string;
}

export interface ICreateOwnerResponse extends IOwner {}

export interface IUpdateOwnerRequest {
    name: string;
}

export interface IUpdateOwnerResponse extends IOwner {}

async function getOwners(param: IGetOwnersRequest): Promise<IGetOwnersResponse> {
    const response = await Api.get('/v1/owners', param);
    return response.data;
}

async function getOwner(id: string): Promise<IOwner> {
    const response = await Api.get(`/v1/owners/${id}`);
    return response.data;
}

async function createOwner(param: ICreateOwnerRequest): Promise<ICreateOwnerResponse> {
    const response = await Api.post('/v1/owners', param);
    return response.data;
}

async function updateOwner(id: string, param: IUpdateOwnerRequest): Promise<IUpdateOwnerResponse> {
    const response = await Api.put(`/v1/owners/${id}`, param);
    return response.data;
}

async function deleteOwner(id: string): Promise<IDeleteOwnerResponse> {
    const response = await Api.delete(`/v1/owners/${id}`);
    return response.data;
}

async function reactivateOwner(id: string): Promise<IReactivateOwnerResponse> {
    const response = await Api.post(`/v1/owners/reactivate/${id}`);
    return response.data;
}

export default {
    getOwners,
    getOwner,
    createOwner,
    updateOwner,
    deleteOwner,
    reactivateOwner,
};
