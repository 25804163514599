import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Spinner,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Stack } from '@chakra-ui/react';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import AuthorsSvc, { IAuthor } from '../../services/AuthorsSvc';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';

const EditAuthorPage: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { id } = useParams();
    const isNewRef = useRef(id === 'new');
    const idRef = useRef(id);
    const loadingRef = useRef(false);

    const [name, setName] = useState('');
    const [erros, setErros] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [author, setAuthor] = useState<IAuthor | null>(null);

    async function createAuthor() {
        try {
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
            });
            schema.validateSync({ name }, { abortEarly: false });
            setLoading(true);

            const response = await AuthorsSvc.createAuthor({
                name,
            });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchAuthor();
            navigate(`/authors/${response.id}`, {
                replace: true,
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar autor',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    async function updateAuthor() {
        try {
            if (!author) {
                return;
            }
            setErros({});
            const schema = yup.object().shape({
                name: yup.string().required('Nome é obrigatório'),
            });
            schema.validateSync({ name }, { abortEarly: false });
            setLoading(true);
            const response = await AuthorsSvc.updateAuthor(author.id, {
                name,
            });
            isNewRef.current = false;
            idRef.current = response.id;
            setLoading(false);
            fetchAuthor();
            toast({
                description: 'Autor salvo com sucesso!',
                duration: 1000,
                status: 'success',
            });
        } catch (error) {
            setLoading(false);
            if (error?.name === 'ValidationError') {
                setErros(
                    (error as yup.ValidationError).inner.reduce(
                        (acc: Object, cur: yup.ValidationError) => ({
                            ...acc,
                            [cur.path as string]: cur.errors[0],
                        }),
                        {}
                    )
                );
            } else {
                toast({
                    description: error?.message ?? 'Erro ao tentar salvar autor',
                    duration: 5000,
                    status: 'error',
                });
            }
        }
    }

    const fetchAuthor = useCallback(async () => {
        try {
            if (!idRef.current || isNewRef.current || loadingRef.current) {
                return;
            }
            loadingRef.current = true;
            setLoading(true);
            const response = await AuthorsSvc.getAuthor(idRef.current);
            setAuthor(response);
            setName(response.name);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast({
                description: error?.message,
                duration: 5000,
                status: 'error',
            });
        } finally {
            loadingRef.current = false;
        }
    }, [toast]);

    useEffect(() => {
        fetchAuthor();
    }, [fetchAuthor]);

    return (
        <Stack>
            <BreadcrumbCustom
                options={[
                    {
                        name: 'Autores',
                        route: '/authors',
                    },
                    {
                        name: isNewRef.current ? 'Novo autor' : 'Editar Autor',
                        route: '',
                        tooltip: isNewRef.current ? undefined : idRef.current,
                    },
                ]}
            />
            <HStack width="25%" justifyContent="center">
                {loading && <Spinner />}
            </HStack>
            <VStack p={5} alignItems="flex-start">
                <HStack width="25%">
                    <FormControl isRequired isInvalid={!!erros['name']}>
                        <FormLabel>Nome do autor</FormLabel>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                        {!!erros['name'] && <FormErrorMessage>{erros['name']}</FormErrorMessage>}
                    </FormControl>
                </HStack>

                <HStack width="25%"></HStack>

                {!!author && (
                    <>
                        <HStack width="25%">
                            <FormControl isReadOnly>
                                <FormLabel>Criado em</FormLabel>
                                <Input value={new Date(author.createdAt).toLocaleString()} />
                            </FormControl>
                        </HStack>
                        {!!author.deletedAt && (
                            <HStack width="25%">
                                <FormControl isReadOnly>
                                    <FormLabel>Deletado em</FormLabel>
                                    <Input value={new Date(author.deletedAt).toLocaleString()} />
                                </FormControl>
                            </HStack>
                        )}
                    </>
                )}

                <HStack paddingTop={3} width="25%">
                    <Button onClick={author ? updateAuthor : createAuthor} colorScheme="blue">
                        Salvar
                    </Button>
                </HStack>
            </VStack>
        </Stack>
    );
};

export default EditAuthorPage;
