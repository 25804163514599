import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Tbody,
    Tfoot,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Text, Stack, Td, Tr, Th, TableContainer, Thead, Table } from '@chakra-ui/react';
import { ChevronDownIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { RiSearch2Line } from 'react-icons/ri';

import MediasSvc, { IMedia } from '../../services/MediasSvc';
import Dialog, { IDialogProps } from '../../components/Dialog';
import BreadcrumbCustom from '../../components/BreadcrumbCustom';
import EditMediaModal from './EditMediaModal';
import SelectAuthor from '../../components/select/SelectAuthor';
import { IAuthor } from '../../services/AuthorsSvc';
import NumberUtils from '../../utils/value/NumberUtils';

const MediasListPage: FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const firstRunRef = useRef(true);
    const loadingRef = useRef(false);
    const setTimeoutRef = useRef<any>(null);
    const searchRef = useRef('');
    const codeRef = useRef('');
    const totalLoadedRef = useRef(0);
    const totalRef = useRef(0);
    const pageRef = useRef(0);
    const authorIdRef = useRef<string | null>(null);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState<any>({});
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [medias, setMedias] = useState<IMedia[]>([]);
    const [propDialog, setPropDialog] = useState<IDialogProps | null>(null);
    const [mediaEdit, setMediaEdit] = useState<IMedia | null>(null);
    const [authorFilter, setAuthorFilter] = useState<IAuthor | null>(null);
    const [code, setCode] = useState('');

    const fetchMedias = useCallback(
        async (reset: boolean) => {
            try {
                if (
                    loadingRef.current ||
                    (totalLoadedRef.current >= totalRef.current && totalRef.current > 0 && !reset)
                ) {
                    return;
                }

                if (reset) {
                    pageRef.current = 0;
                    totalRef.current = 0;
                    totalLoadedRef.current = 0;
                    setMedias([]);
                }

                loadingRef.current = true;

                setLoading(true);
                const response = await MediasSvc.getMedias({
                    page: pageRef.current + 1,
                    limit: 20,
                    title: searchRef.current,
                    authorId: authorIdRef.current,
                    code: codeRef.current,
                    order: 'title',
                });
                if (reset) {
                    setMedias(response.result);
                } else {
                    setMedias((val) => {
                        const valCopy = [...val, ...response.result];
                        totalLoadedRef.current = valCopy.length;
                        return valCopy;
                    });
                }
                setTotal(response.total);
                pageRef.current = response.page;
                totalRef.current = response.total;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                loadingRef.current = false;
            }
        },
        [toast]
    );

    const deleteOrReactivateMedia = useCallback(
        async (media: IMedia) => {
            try {
                setLoadingAction((val: any) => ({ ...val, [media.id]: true }));
                const action = media.deletedAt ? MediasSvc.reactivateMedia : MediasSvc.deleteMedia;
                const response = await action(media.id);
                toast({
                    description: response?.message,
                    duration: 2000,
                    status: 'success',
                });
                fetchMedias(true);
            } catch (error) {
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                setLoadingAction((val: any) => {
                    let copy = { ...val };
                    delete copy[media.id];
                    return copy;
                });
            }
        },
        [fetchMedias, toast]
    );

    const openModalDeleteOrReactivate = useCallback(
        (media: IMedia) => {
            setPropDialog({
                title: 'Confirmação',
                message: `Realmente deseja excluir a mídia?`,
                onOk: () => deleteOrReactivateMedia(media),
                onClose: () => setPropDialog(null),
            });
        },
        [deleteOrReactivateMedia]
    );

    useEffect(() => {
        function listenerScroll() {
            const totalHeight = document.body.scrollHeight;
            const offset = window.pageYOffset + window.innerHeight;
            if (offset >= totalHeight * 0.9 && !loadingRef.current) {
                fetchMedias(false);
            }
        }
        window.addEventListener('scroll', listenerScroll);
        return () => window.removeEventListener('scroll', listenerScroll);
    }, [fetchMedias]);

    useEffect(() => {
        if (firstRunRef.current) {
            fetchMedias(true);
            firstRunRef.current = false;
        }
    }, [fetchMedias]);

    return (
        <Stack>
            <HStack justifyContent="space-between" paddingRight={5}>
                <BreadcrumbCustom
                    options={[
                        {
                            name: 'Mídias',
                            route: '/media',
                        },
                    ]}
                />
                <Text>Total de registros: {totalRef.current}</Text>
            </HStack>
            <HStack paddingBottom={5} paddingLeft={5} paddingRight={5} justifyContent="space-between" flexWrap="wrap">
                <HStack>
                    <HStack>
                        <FormControl>
                            <FormLabel>Título</FormLabel>
                            <InputGroup size="md">
                                <Input
                                    pr="4.5rem"
                                    placeholder="Pesquisar..."
                                    value={searchText}
                                    onChange={(e) => {
                                        clearTimeout(setTimeoutRef.current);
                                        setTimeoutRef.current = setTimeout(() => {
                                            searchRef.current = e.target.value;
                                            fetchMedias(true);
                                        }, 1000);
                                        setSearchText(e.target.value);
                                    }}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={() => fetchMedias(true)}>
                                        <SearchIcon />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    </HStack>
                    <HStack>
                        <FormControl>
                            <FormLabel>Código</FormLabel>
                            <InputGroup>
                                <Input
                                    type="text"
                                    inputMode="numeric"
                                    value={code ?? ''}
                                    onChange={() => {}}
                                    onKeyDown={(e) => {
                                        clearTimeout(setTimeoutRef.current);
                                        const val = NumberUtils.inputKeyCode(code, e.nativeEvent.key);
                                        setTimeoutRef.current = setTimeout(() => {
                                            codeRef.current = val;
                                            fetchMedias(true);
                                        }, 1000);
                                        setCode(val);
                                    }}
                                    onPaste={(e) => {
                                        const val = NumberUtils.formatCodeOnPaste(e.clipboardData.getData('Text'));
                                        setTimeoutRef.current = setTimeout(() => {
                                            codeRef.current = val;
                                            fetchMedias(true);
                                        }, 1000);
                                        setCode(val);
                                    }}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={() => fetchMedias(true)}>
                                        <SearchIcon />
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    </HStack>
                    <HStack>
                        <FormControl minWidth="25%">
                            <FormLabel>Autor</FormLabel>
                            <SelectAuthor
                                placeholder="Selecione um autor"
                                value={authorFilter}
                                onChange={(e) => {
                                    authorIdRef.current = e?.id ?? null;
                                    setAuthorFilter(e);
                                    fetchMedias(true);
                                }}
                            />
                        </FormControl>
                    </HStack>
                </HStack>

                <HStack justifyContent="space-between">
                    <IconButton aria-label="Search database" icon={<RepeatIcon />} onClick={() => fetchMedias(true)} />
                    <Button
                        onClick={() => {
                            setMediaEdit(null);
                            onOpen();
                        }}
                        colorScheme="blue"
                    >
                        Nova Mídia
                    </Button>
                </HStack>
            </HStack>

            <TableContainer paddingLeft={5} paddingRight={5}>
                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Th textAlign="left">#</Th>
                            <Th textAlign="left">Título</Th>
                            <Th textAlign="left">Formato</Th>
                            <Th textAlign="left">Autores</Th>
                            <Th textAlign="center">Código</Th>
                            <Th textAlign="center">Volume</Th>
                            <Th textAlign="center">Edição</Th>
                            <Th textAlign="center">Local</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {medias.map((item, index) => (
                            <Tr
                                key={item.id}
                                css={{ cursor: 'pointer' }}
                                _hover={{ backgroundColor: '#232a3a' }}
                                onClick={() => {
                                    setMediaEdit(item);
                                    onOpen();
                                }}
                            >
                                <Td textAlign="left">{index + 1}</Td>
                                <Td textAlign="left">{item.title}</Td>
                                <Td textAlign="left">{!!item.publicationFormatId && item?.publicationFormat?.name}</Td>
                                <Td textAlign="left">
                                    <VStack alignItems="flex-start">
                                        {item.authors?.map((it) => (
                                            <Text key={it.id}>{it.name}</Text>
                                        ))}
                                    </VStack>
                                </Td>
                                <Td textAlign="center">{item.code}</Td>
                                <Td textAlign="center">{item.volume}</Td>
                                <Td textAlign="center">{item.edition}</Td>
                                <Td textAlign="center">{item.local}</Td>
                                <Td textAlign="right">
                                    <Menu>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<ChevronDownIcon />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            Ações
                                            {!!loadingAction[item.id] && <Spinner size="xs" marginLeft={2} />}
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toast({
                                                        description: item.id,
                                                        duration: 5000,
                                                        status: 'info',
                                                    });
                                                }}
                                            >
                                                Ver Id
                                            </MenuItem>

                                            <MenuItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openModalDeleteOrReactivate(item);
                                                }}
                                            >
                                                {item.deletedAt ? 'Restaurar' : 'Deletar'}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th colSpan={10}>
                                {medias.length > 0 ? (
                                    <HStack justifyContent="center" p={5}>
                                        <Text>
                                            {medias.length} de {total} registros
                                        </Text>
                                        {loading && <Spinner />}
                                    </HStack>
                                ) : (
                                    <HStack justifyContent="center" p={5}>
                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <>
                                                <Text>Nenhuma informação encontrada</Text>
                                                <RiSearch2Line size={15} />
                                            </>
                                        )}
                                    </HStack>
                                )}
                            </Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
            {propDialog && <Dialog {...propDialog} />}
            {isOpen && <EditMediaModal isOpen={isOpen} onClose={onClose} media={mediaEdit} fetchMedias={fetchMedias} />}
        </Stack>
    );
};

export default MediasListPage;
