import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export interface IRating {
    id: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetRatingsRequest extends IPaginateRequest {
    search?: string | null;
    order?: string | null;
    deleted?: boolean;
}

export interface IGetRatingsResponse extends IPaginateResponse<IRating> {}

export interface IDeleteRatingResponse {
    message: string;
}

export interface IReactivateRatingResponse {
    message: string;
}

export interface IReprocessBalanceResponse {
    message: string;
}

export interface ICreateRatingRequest {
    name: string;
}

export interface ICreateRatingResponse extends IRating {}

export interface IUpdateRatingRequest {
    name: string;
}

export interface IUpdateRatingResponse extends IRating {}

async function getRatings(param: IGetRatingsRequest): Promise<IGetRatingsResponse> {
    const response = await Api.get('/v1/ratings', param);
    return response.data;
}

async function getRating(id: string): Promise<IRating> {
    const response = await Api.get(`/v1/ratings/${id}`);
    return response.data;
}

async function createRating(param: ICreateRatingRequest): Promise<ICreateRatingResponse> {
    const response = await Api.post('/v1/ratings', param);
    return response.data;
}

async function updateRating(id: string, param: IUpdateRatingRequest): Promise<IUpdateRatingResponse> {
    const response = await Api.put(`/v1/ratings/${id}`, param);
    return response.data;
}

async function deleteRating(id: string): Promise<IDeleteRatingResponse> {
    const response = await Api.delete(`/v1/ratings/${id}`);
    return response.data;
}

async function reactivateRating(id: string): Promise<IReactivateRatingResponse> {
    const response = await Api.post(`/v1/ratings/reactivate/${id}`);
    return response.data;
}

export default {
    getRatings,
    getRating,
    createRating,
    updateRating,
    deleteRating,
    reactivateRating,
};
