import Api, { IPaginateRequest, IPaginateResponse } from '../api/Api';

export interface IPublisher {
    id: string;
    name: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
}

export interface IGetPublishersRequest extends IPaginateRequest {
    search?: string | null;
    order?: string | null;
    deleted?: boolean;
}

export interface IGetPublishersResponse extends IPaginateResponse<IPublisher> {}

export interface IDeletePublisherResponse {
    message: string;
}

export interface IReactivatePublisherResponse {
    message: string;
}

export interface IReprocessBalanceResponse {
    message: string;
}

export interface ICreatePublisherRequest {
    name: string;
}

export interface ICreatePublisherResponse extends IPublisher {}

export interface IUpdatePublisherRequest {
    name: string;
}

export interface IUpdatePublisherResponse extends IPublisher {}

async function getPublishers(param: IGetPublishersRequest): Promise<IGetPublishersResponse> {
    const response = await Api.get('/v1/publishers', param);
    return response.data;
}

async function getPublisher(id: string): Promise<IPublisher> {
    const response = await Api.get(`/v1/publishers/${id}`);
    return response.data;
}

async function createPublisher(param: ICreatePublisherRequest): Promise<ICreatePublisherResponse> {
    const response = await Api.post('/v1/publishers', param);
    return response.data;
}

async function updatePublisher(id: string, param: IUpdatePublisherRequest): Promise<IUpdatePublisherResponse> {
    const response = await Api.put(`/v1/publishers/${id}`, param);
    return response.data;
}

async function deletePublisher(id: string): Promise<IDeletePublisherResponse> {
    const response = await Api.delete(`/v1/publishers/${id}`);
    return response.data;
}

async function reactivatePublisher(id: string): Promise<IReactivatePublisherResponse> {
    const response = await Api.post(`/v1/publishers/reactivate/${id}`);
    return response.data;
}

export default {
    getPublishers,
    getPublisher,
    createPublisher,
    updatePublisher,
    deletePublisher,
    reactivatePublisher,
};
