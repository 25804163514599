import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    Button,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Tab,
    Table,
    TableContainer,
    TabList,
    Tabs,
    Tbody,
    Td,
    Tfoot,
    Th,
    Tr,
    useToast,
    Text,
    Modal,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import AuthorsSvc, { IAuthor } from '../../services/AuthorsSvc';
import { RiSearch2Line } from 'react-icons/ri';

export interface ISelectAuthorProps {
    placeholder?: string;
    value: IAuthor | null;
    onChange: (item: IAuthor | null) => void;
    hiddenButtonCreate?: boolean;
}

const SelectAuthor: FC<ISelectAuthorProps> = ({ placeholder, value, onChange, hiddenButtonCreate }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    function openModal() {
        onOpen();
    }

    return (
        <>
            <InputGroup>
                <Input
                    cursor="pointer"
                    pr="4.5rem"
                    readOnly
                    placeholder={placeholder}
                    onClick={openModal}
                    value={!!value ? value.name : ''}
                />
                <InputRightElement width="6.3rem">
                    <Button h="2.1rem" size="sm" onClick={() => onChange(null)} variant="link">
                        <CloseIcon fontSize={9} />
                    </Button>
                </InputRightElement>
                <InputRightElement width="3rem">
                    <Button h="2.1rem" size="sm" onClick={openModal} variant="link">
                        <ChevronDownIcon fontSize={20} />
                    </Button>
                </InputRightElement>
            </InputGroup>
            {isOpen && (
                <ModalListAuthor
                    isOpen={isOpen}
                    onClose={onClose}
                    onSelected={onChange}
                    placeholder={placeholder}
                    hiddenButtonCreate={hiddenButtonCreate}
                />
            )}
        </>
    );
};

interface IModalListAuthorProps {
    isOpen: boolean;
    onClose: () => void;
    onSelected: (item: IAuthor) => void;
    placeholder?: string;
    hiddenButtonCreate?: boolean;
}

const ModalListAuthor: FC<IModalListAuthorProps> = ({
    isOpen,
    onClose,
    onSelected,
    placeholder,
    hiddenButtonCreate = false,
}) => {
    const toast = useToast();
    const firstRunRef = useRef(true);
    const loadingRef = useRef(false);
    const setTimeoutRef = useRef<any>(null);
    const searchRef = useRef('');
    const totalLoadedRef = useRef(0);
    const totalRef = useRef(0);
    const pageRef = useRef(0);
    const tabIndexRef = useRef(0);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState<number | undefined>(undefined);
    const [authors, setAuthors] = useState<IAuthor[]>([]);

    function redirectToCreate() {
        window.open('/authors/new', '_blank');
    }

    const fetchAuthors = useCallback(
        async (reset: boolean) => {
            try {
                if (
                    loadingRef.current ||
                    (totalLoadedRef.current >= totalRef.current && totalRef.current > 0 && !reset)
                ) {
                    return;
                }

                if (reset) {
                    pageRef.current = 0;
                    totalRef.current = 0;
                    totalLoadedRef.current = 0;
                    setAuthors([]);
                }

                loadingRef.current = true;

                setLoading(true);
                const response = await AuthorsSvc.getAuthors({
                    page: pageRef.current + 1,
                    limit: 10,
                    search: searchRef.current,
                    deleted: tabIndexRef.current === 1,
                    order: 'name',
                });
                if (reset) {
                    setAuthors(response.result);
                } else {
                    setAuthors((val) => {
                        const valCopy = [...val, ...response.result];
                        totalLoadedRef.current = valCopy.length;
                        return valCopy;
                    });
                }

                setTotal(response.total);
                pageRef.current = response.page;
                totalRef.current = response.total;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast({
                    description: error?.message,
                    duration: 5000,
                    status: 'error',
                });
            } finally {
                loadingRef.current = false;
            }
        },
        [toast]
    );

    useEffect(() => {
        function listenerScroll() {
            const totalHeight = document.body.scrollHeight;
            const offset = window.pageYOffset + window.innerHeight;
            if (offset >= totalHeight * 0.9 && !loadingRef.current) {
                fetchAuthors(false);
            }
        }
        window.addEventListener('scroll', listenerScroll);
        return () => window.removeEventListener('scroll', listenerScroll);
    }, [fetchAuthors]);

    useEffect(() => {
        if (firstRunRef.current) {
            fetchAuthors(true);
            firstRunRef.current = false;
        }
    }, [fetchAuthors]);

    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered scrollBehavior="inside" size="md">
            <ModalOverlay />
            <ModalContent height="70vh">
                <ModalHeader paddingBottom={0}>
                    <HStack>
                        <Text>{placeholder}</Text>
                    </HStack>
                    <HStack marginTop={5}>
                        <InputGroup size="md">
                            <Input
                                pr="4.5rem"
                                placeholder="Pesquisar..."
                                value={searchText}
                                onChange={(e) => {
                                    clearTimeout(setTimeoutRef.current);
                                    setTimeoutRef.current = setTimeout(() => {
                                        searchRef.current = e.target.value;
                                        fetchAuthors(true);
                                    }, 500);
                                    setSearchText(e.target.value);
                                }}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => fetchAuthors(true)}>
                                    <SearchIcon />
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Tabs
                            onChange={(index) => {
                                tabIndexRef.current = index;
                                fetchAuthors(true);
                            }}
                        >
                            <TabList>
                                <Tab>Ativos</Tab>
                                <Tab>Deletados</Tab>
                            </TabList>
                        </Tabs>
                    </HStack>
                    <HStack justifyContent="space-between" alignItems="flex-end" marginTop={5}>
                        <Text className="css-dyi6ss">Nome</Text>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    onScroll={(e: any) => {
                        const { scrollHeight, scrollTop, clientHeight } = e.target;
                        const scroll = scrollHeight - scrollTop - clientHeight;
                        if (scroll <= 5 && !loadingRef.current) {
                            fetchAuthors(false);
                        }
                    }}
                >
                    <TableContainer>
                        <Table variant="simple" layout="fixed">
                            <Tbody>
                                {authors.map((item) => (
                                    <Tr
                                        key={item.id}
                                        css={{
                                            ':hover': {
                                                backgroundColor: '#6e6e6e41',
                                            },
                                        }}
                                        cursor="pointer"
                                        onClick={() => {
                                            onSelected(item);
                                            onClose();
                                        }}
                                    >
                                        <Td
                                            css={{
                                                wordBreak: 'break-all',
                                                whiteSpace: 'normal',
                                            }}
                                            borderColor="#ffffff41"
                                        >
                                            {item.name}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th colSpan={1}>
                                        {authors.length > 0 ? (
                                            loading && (
                                                <HStack justifyContent="center" p={5}>
                                                    <Spinner />
                                                </HStack>
                                            )
                                        ) : (
                                            <VStack>
                                                <HStack justifyContent="center" p={5}>
                                                    {loading ? (
                                                        <Spinner />
                                                    ) : (
                                                        <>
                                                            <Text>Nenhuma informação encontrada</Text>
                                                            <RiSearch2Line size={15} />
                                                        </>
                                                    )}
                                                </HStack>
                                                {!hiddenButtonCreate && (
                                                    <Button onClick={redirectToCreate} variant="outline">
                                                        Criar novo
                                                    </Button>
                                                )}
                                            </VStack>
                                        )}
                                    </Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter justifyContent="space-between">
                    <Text className="css-dyi6ss">
                        {authors.length > 0 && `${authors.length} de ${total} registros`}
                    </Text>
                    <HStack justifyContent="flex-end">
                        <Button onClick={onClose}>Cancelar</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SelectAuthor;
