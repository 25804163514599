import Router from './Router';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const theme = extendTheme({ config });

export const App = () => (
    <ChakraProvider theme={theme}>
        <Router />
    </ChakraProvider>
);
