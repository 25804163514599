import { Route, Routes } from 'react-router-dom';

import Session from '../utils/session/Session';
import AuthPage from './auth/AuthPage';
import MainPage from './main/MainPage';

export default function Router() {
    return (
        <Routes>
            <Route path="/auth" element={<AuthPage />} />
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <MainPage />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

function PrivateRoute({ children }: { children: JSX.Element }) {
    if (!!Session.getAccessToken()) {
        return children;
    } else {
        Session.logout();
        return <div />;
    }
}
