import axios from 'axios';
import { errorResponse } from '../api/Api';
import { ISession } from '../utils/session/Session';

export interface IGetTokenRequest {
    authorizationToken: string;
}

export interface IGetTokenResponse extends ISession {}

export interface IRefreshTokenResponse {
    accessToken: string;
    refreshToken: string;
}

async function getToken(param: IGetTokenRequest): Promise<IGetTokenResponse> {
    try {
        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/v1/auth/code`, param);
        return response.data;
    } catch (error) {
        throw errorResponse(error);
    }
}

async function refreshToken(refreshToken: string): Promise<IRefreshTokenResponse> {
    try {
        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/v1/auth/refreshToken`, { refreshToken });
        return response.data;
    } catch (error) {
        throw errorResponse(error);
    }
}

export default {
    getToken,
    refreshToken,
};
